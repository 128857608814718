.password-icon {
  background-position: right 30px center !important;
}
.multiSelectContainer li:hover {
  background-color: var(--kt-primary-light);
  color: black;
}

.toast-message {
  .Toastify__toast-body {
    .Toastify__toast-icon {
      // color: #50cd89 !important;
    }
  }

  .Toastify__progress-bar--error {
    // background: #50cd89 !important;
  }
}

.search-bar {
  width: 250px;
  @media screen and #{$break-xs} {
    width: 180px;
  }
}

.app-header {
  @media screen and #{$break-md-close} {
    background-color: white !important;
  }
}
